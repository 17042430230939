import React from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import Layout from '../../../../components/layout';

function CheckoutSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for registering to virtually attend the GMIS Connect China webinar
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            which will be aired live at 12PM GST on Friday 4th November, 2022.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}

export default CheckoutSuccess;
